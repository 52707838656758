import Swal from "sweetalert2";
import {useUtil} from "~/composables/useUtil";
import {useCSRF} from "~/stores/csrf";
import {useAuthStore} from "~/stores/auth";

export function useContact() {
    const authStore = useAuthStore();

    const sendContact = async (data) => {
        await useAPI().apiFetch('/client/contact', {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({data}) => {
            Swal.fire({
                title: 'Correo envíado',
                text: 'En breves nos pondremos en contacto contigo.',
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Entendido",
                heightAuto: false,
                customClass: {
                    confirmButton: "btn fw-semibold btn-primary",
                },
            });
        }).catch(({response}) => {
            if (response) {
                useUtil().defaultError(response._data.status, response._data.message);
            } else {
                useUtil().defaultError('Error', 'No hay conexión');
            }
        });
    };

    const resendVerificationEmail = async (data) => {
        Swal.fire({
            title: '¿Seguro que quieres continuar?',
            icon: "info",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Si, continuar",
            cancelButtonText: "No, cancelar",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
                cancelButton: "btn fw-semibold btn-outline btn-outline-primary",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                await useUtil().showLoading();
                await useAPI().apiFetch('/auth/email-verify-resend', {
                    method: 'POST',
                }).then( async ({data}) => {
                    await useUtil().closeLoading();
                    await Swal.fire({
                        title: 'Correo reenvíado',
                        text: 'Por favor, revisa tu bandeja de entrada.',
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Entendido",
                        heightAuto: false,
                        customClass: {
                            confirmButton: "btn fw-semibold btn-primary",
                        },
                    });
                }).catch( async ({response}) => {
                    await useUtil().closeLoading();
                    if (response) {
                        useUtil().defaultError(response._data.status, response._data.message);
                    } else {
                        useUtil().defaultError('Error', 'No hay conexión');
                    }
                });
            }
        });
    };

    const requestCancelSubscription = async (data) => {
        Swal.fire({
            title: '¿Seguro que quieres cancelar tu suscripción?',
            text: 'Enviarás una solicitud a soporte y se valorará tu caso.',
            icon: "info",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Si, continuar",
            cancelButtonText: "No, cancelar",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
                cancelButton: "btn fw-semibold btn-outline btn-outline-primary",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                await useUtil().showLoading();
                await useAPI().apiFetch('/client/cancel-subscription', {
                    method: 'POST',
                }).then( async ({data}) => {
                    await authStore.refreshUser();
                    await useUtil().closeLoading();
                    await Swal.fire({
                        title: 'Solicitud enviada',
                        text: 'Nos pondremos en contacto contigo lo antes posible.',
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Entendido",
                        heightAuto: false,
                        customClass: {
                            confirmButton: "btn fw-semibold btn-primary",
                        },
                    });
                }).catch( async ({response}) => {
                    await useUtil().closeLoading();
                    if (response) {
                        useUtil().defaultError(response._data.status, response._data.message);
                    } else {
                        useUtil().defaultError('Error', 'No hay conexión');
                    }
                });
            }
        });
    };

    const requestCancelDisableUser = async () => {
        Swal.fire({
            title: '¿Seguro que quieres solicitar la cancelación?',
            text: 'Enviarás una solicitud a soporte y se valorará tu caso.',
            icon: "info",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Si, continuar",
            cancelButtonText: "No, cancelar",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
                cancelButton: "btn fw-semibold btn-outline btn-outline-primary",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                await useUtil().showLoading();
                await useAPI().apiFetch('/client/cancel-disable', {
                    method: 'POST',
                }).then( async ({data}) => {
                    await authStore.refreshUser();
                    await useUtil().closeLoading();
                    await Swal.fire({
                        title: 'Solicitud enviada',
                        text: 'Nos pondremos en contacto contigo lo antes posible.',
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Entendido",
                        heightAuto: false,
                        customClass: {
                            confirmButton: "btn fw-semibold btn-primary",
                        },
                    });
                }).catch( async ({response}) => {
                    await useUtil().closeLoading();
                    if (response) {
                        useUtil().defaultError(response._data.status, response._data.message);
                    } else {
                        useUtil().defaultError('Error', 'No hay conexión');
                    }
                });
            }
        });
    };

    const requestCancelSimpatizanteUser = async () => {
        Swal.fire({
            title: '¿Seguro que quieres continuar?',
            text: 'Podrás disfrutar de las ventajas de ser Simpatizante',
            icon: "info",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Si, continuar",
            cancelButtonText: "No, cancelar",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
                cancelButton: "btn fw-semibold btn-outline btn-outline-primary",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                await useUtil().showLoading();
                await useAPI().apiFetch('/client/switch-to-simpatizante', {
                    method: 'POST',
                }).then( async ({data}) => {
                    await authStore.refreshUser();
                    await useUtil().closeLoading();
                    await Swal.fire({
                        title: '¡Muchas gracias por confiar en ASUFIN!',
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Entendido",
                        heightAuto: false,
                        customClass: {
                            confirmButton: "btn fw-semibold btn-primary",
                        },
                    });
                }).catch( async ({response}) => {
                    await useUtil().closeLoading();
                    if (response) {
                        useUtil().defaultError(response._data.status, response._data.message);
                    } else {
                        useUtil().defaultError('Error', 'No hay conexión');
                    }
                });
            }
        });
    };

    return {
        sendContact,
        resendVerificationEmail,
        requestCancelSubscription,
        requestCancelDisableUser,
        requestCancelSimpatizanteUser,
    };
}
